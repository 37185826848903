import { useEffect, useState } from 'react'
import {
  approveLocationAccessRequest,
  declineLocationAccessRequest,
} from '../../../services/locationAccessRequest'
import AccessRequestData from '../../../models/AccessRequestData'
import {
  ToasterToastProps,
  ToastProviderProps,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import useAccessQuery from '../../../queries/access/useAccessQuery'
import apiConfig from '../../../apiConfig'

export default function useAccess(lanId: string) {
  const [locationAccessRequests, setLocationAccessRequests] = useState<
    AccessRequestData[]
  >([])

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalIsRejectMode, setmodalIsReject] = useState<boolean>(false)
  const [locationAccessData, setLocationAccessData] =
    useState<AccessRequestData | null>(null)

  const makeToast = useToaster()
  const toastSuccessConfig = {
    className: 'access-toaster',
    type: 'success',
    heading: 'Success',
  }

  const toastErrorConfig = {
    className: 'access-toaster',
    type: 'error',
    heading: 'Something went wrong',
    message: 'Please try again',
  }

  const {
    isFetching,
    data = [],
    isError,
    isSuccess,
    refetch: refetchAccessRequests,
  } = useAccessQuery(apiConfig.rbac?.locationAccess, lanId)

  useEffect(() => {
    setLocationAccessRequests(filterActiveLocationRequests(data).reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  function onApprove(accessRequest: AccessRequestData) {
    setmodalIsReject(false)
    openModal()

    setLocationAccessData(accessRequest)
  }

  function onReject(accessRequest: AccessRequestData) {
    setmodalIsReject(true)
    setModalOpen(true)

    setLocationAccessData(accessRequest)
  }

  function submitApproval(accessRequest: AccessRequestData) {
    closeModal()
    approveLocationAccessRequest(Number.parseInt(accessRequest.la_id), '1')
      .then(() => {
        refetchAccessRequests()
        makeToast({
          ...toastSuccessConfig,
          message: 'Approval submitted',
        } as ToasterToastProps)
      })
      .catch(() => {
        makeToast({ ...toastErrorConfig } as ToastProviderProps)
      })
  }

  function submitRejection(
    rejectReason: string,
    accessRequest: AccessRequestData,
  ) {
    closeModal()
    declineLocationAccessRequest(
      Number.parseInt(accessRequest.la_id),
      '2',
      rejectReason,
    )
      .then(() => {
        refetchAccessRequests()
        makeToast({
          ...toastSuccessConfig,
          message: 'Denial submitted',
        } as ToasterToastProps)
      })
      .catch(() => {
        makeToast({ ...toastErrorConfig } as ToasterToastProps)
      })
  }

  function filterActiveLocationRequests(requests: AccessRequestData[]) {
    // Filter out expired requests and requests that are not in pending state
    return requests.filter(
      (request) =>
        new Date(request.end_date_access).setUTCHours(23, 59, 59, 999) >=
          new Date().getTime() && parseInt(request.state) === 0,
    )
  }

  return {
    closeModal,
    isError,
    isFetching,
    isSuccess,
    locationAccessRequests,
    locationAccessData,
    modalIsRejectMode,
    modalOpen,
    onApprove,
    onReject,
    setLocationAccessRequests,
    submitApproval,
    submitRejection,
  }
}
