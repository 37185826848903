/*
   // these were on the OG implementation of the connection. We probably can clean this up a bit.
    cameraId,
    auth: authConfig,
    streamProfile,
    location_id
*/

export default class mjpegWebSocket {
  websocket: WebSocket | null

  constructor(connectionDetails: any) {
    // essentially, with the details, we wire up the websocket connection here
    if ('WebSocket' in window) {
      this.websocket = new WebSocket(connectionDetails.url, [
        connectionDetails.authToken.replace('Bearer ', ''),
      ])
      this.websocket.binaryType = 'arraybuffer'

      this.websocket.onopen = function () {
        let streamRequest = {
          cameraId: connectionDetails.cameraId,
          streamType: 'MJPEG',
          stream_profile: 'TrueyeMob',
          access_token: connectionDetails.authToken,
          id_token: null,
          location_id: connectionDetails.locationId,
        }
        this.send(Buffer.from(JSON.stringify(streamRequest)))
      }
      this.websocket.onmessage = (message) => {
        if (this.websocket?.readyState === WebSocket.OPEN) {
          console.log('connection opened')
          if (connectionDetails?.imageRef?.current) {
            let blob = new Blob([new Uint8Array(message.data)])
            connectionDetails.imageRef.current.src = URL.createObjectURL(blob)
          }
        }
      }
    } else {
      this.websocket = null
      console.log('Websockets are not supported by this browser.')
    }
  }

  close() {
    try {
      if (this.websocket) {
        this.websocket.close(1000)
      }
    } catch (error) {
      console.log(error)
    }
  }
}
