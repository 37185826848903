import EnterpriseIcon, { MarketplaceIcon } from '@enterprise-ui/icons'
import {
  CloseFunctionButton,
  LocationBanner,
  LocationInfoContainer,
} from './styles'
import { StoreInfoText, StoreInfoTextBox } from '../CameraDrawer/styles'

interface LocationHeaderProps {
  collapseButtonClick: Function
  locationId: string
  locationName: string
}

export const LocationHeader = ({
  collapseButtonClick,
  locationId,
  locationName,
}: LocationHeaderProps) => {
  return (
    <LocationBanner>
      <LocationInfoContainer>
        <EnterpriseIcon icon={MarketplaceIcon} />
        <StoreInfoTextBox>
          <StoreInfoText>{locationId}</StoreInfoText>{' '}
          <StoreInfoText>{locationName}</StoreInfoText>
        </StoreInfoTextBox>
      </LocationInfoContainer>
      <CloseFunctionButton onClick={collapseButtonClick} type="ghost">
        Change Location
      </CloseFunctionButton>
    </LocationBanner>
  )
}
