import { useState } from 'react'

export const MONITOR_MODE = 'Monitor'
export const RBAC_MODE = 'Access'
export const PROFILE_MODE = 'Profile'
export const FAVORITES_MODE = 'Favorites'
export const CAMERA_TREE = 'Camera Tree'

export type AppMode =
  | typeof MONITOR_MODE
  | typeof RBAC_MODE
  | typeof PROFILE_MODE
  | typeof FAVORITES_MODE

export default function useMyTvs() {
  const [currentMode, setCurrentMode] = useState<AppMode>(MONITOR_MODE)
  const [createFavoriteOpen, setCreateFavoriteOpen] = useState<boolean>(false)
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false)

  function openCreateFavorite() {
    setCreateFavoriteOpen(true)
  }
  function closeCreateFavorite() {
    setCreateFavoriteOpen(false)
  }
  return {
    currentMode,
    closeCreateFavorite,
    createFavoriteOpen,
    openCreateFavorite,
    setCurrentMode,
    setVideoIsPlaying,
    videoIsPlaying,
  }
}
