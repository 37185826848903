import { useEffect, useState } from 'react'
import { Layout, useLayoutQuery } from 'trueye-component-library'
import apiConfig from '../../../apiConfig'

export default function useFavorites(
  lanId: string,
  playingLayout: Layout | null,
) {
  const [publicLayouts, setPublicLayouts] = useState<Layout[]>([])
  const [privateLayouts, setPrivateLayouts] = useState<Layout[]>([])

  const [waitlistedLayout, setWaitlistedLayout] = useState<Layout | null>(null)

  const {
    data = [],
    isFetching,
    isError,
    isSuccess,
  } = useLayoutQuery(
    apiConfig.camera?.layout || '',
    lanId.toUpperCase(),
    'myTvs',
  ) // TODO make app name a const

  useEffect(() => {
    const newPublicLayouts: Layout[] = []
    const newPrivateLayouts: Layout[] = []
    data.forEach((layout: Layout) => {
      if (layout.isPublic) {
        newPublicLayouts.push(layout)
      } else {
        newPrivateLayouts.push(layout)
      }
    })
    setPublicLayouts(newPublicLayouts)
    setPrivateLayouts(newPrivateLayouts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  useEffect(() => {
    setWaitlistedLayout(playingLayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingLayout])

  return {
    isError,
    isFetching,
    isSuccess,
    privateLayouts,
    publicLayouts,
    waitlistedLayout,
    setWaitlistedLayout,
  }
}
