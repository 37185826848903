import { useQuery } from 'react-query'
import axios from 'axios'
import QueryDefaults from '../defaults'

export default function useAccessQuery(
  apiURL?: string | undefined,
  userId?: string | undefined,
  fetchOnLoad: boolean = QueryDefaults.FETCH_ON_LOAD,
  staleTime: number = QueryDefaults.STALE_TIME,
  cacheTime: number = QueryDefaults.CACHE_TIME,
) {
  const fetchData = async () => {
    const response = await axios.get(`${apiURL}/pending_request`)
    if (!response.data) {
      throw new Error('Data not found')
    }
    return response.data
  }

  return useQuery<any>(['locationAccess'], fetchData, {
    enabled: fetchOnLoad,
    staleTime: staleTime,
    cacheTime: cacheTime,
  })
}
