import { useEffect, useRef, useState } from 'react'
import { QueryDefaults, useCameraQuery } from 'trueye-component-library'
import apiConfig from '../../../apiConfig'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import mjpegWebSocket from '../websockets/mjpegWs'

export default function useVideoCard(
  authToken: string,
  cameraData: CameraTreeItem,
) {
  const imageRef = useRef<HTMLImageElement>(null)
  // const [videoStatus, setVideoStatus] = useState<number>(0)
  // const [currentTime, setCurrentTime] = useState<number>(Date.now())
  const [videoWs, setVideoWs] = useState<mjpegWebSocket>()

  const { data, isFetching, isSuccess } = useCameraQuery(
    `${apiConfig.camera?.getCameraInfo}/${cameraData.id}` || '',
    true,
    QueryDefaults.STALE_TIME,
    cameraData.id,
  )

  useEffect(() => {
    return () => {
      if (videoWs?.close) {
        videoWs.close()
      }
    }
  }, [videoWs])

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        const camera = data[0]
        if (camera.camera_condition === 'online') {
          const socketConfig = {
            url: `${apiConfig.stream?.streamerService}`,
            cameraId: camera.id,
            imageRef: imageRef,
            authToken: authToken.replace('Bearer ', ''),
            locationId: camera.location_id,
            onMessge: (message: any) => {
              console.log(message)
            },
          }
          setVideoWs(new mjpegWebSocket(socketConfig))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return { data, imageRef, isFetching }
}
