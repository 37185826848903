import { CAMERA_TREE_TAB_HEIGHT } from '../CameraTree/styles'
import { HEADER_HEIGHT } from '../Header/styles'
import styled from 'styled-components'

export const CameraTreeOverlay = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  background: black;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  z-index: 999;
  overflow: auto; /* Allows scrolling if needed */

  &.\--is-open {
    transform: translateX(0);
  }

  color: white;
`

export const StoreSelectContainer = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px - ${CAMERA_TREE_TAB_HEIGHT}px);
  overflow: auto;
  padding: 15px 15px 0;
  width: 100%;
`

export const CameraFolderHeader = styled.div`
  background: #2e2e2e;
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 16px;
`

export const CameraInfoTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #bdbdbd;
`

export const StoreInfoTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  color: inherit;
`

export const StoreInfoText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
`
