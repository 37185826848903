import { useState } from 'react'
import Location from 'trueye-component-library/dist/models/location/Location'

export const CAMERA_TREE_MODE = 'Camera Tree'
export const FAVORITES_MODE = 'Favorites'

export default function useDrawer() {
  const [drawerMode, setDrawerMode] = useState<string>(CAMERA_TREE_MODE)
  const [openLocationData, setOpenLocationData] = useState<Location | null>(
    null,
  )

  function closeLocation() {
    setOpenLocationData(null)
  }

  return {
    drawerMode,
    openLocationData,
    setDrawerMode,
    setOpenLocationData,
    closeLocation,
  }
}
