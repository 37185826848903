import { useEffect } from 'react'
import '@enterprise-ui/canvas-ui-css'
import Header from './components/Header'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { ChevronLeftIcon, MenuIcon } from '@enterprise-ui/icons'
import WorkZone from './pages/WorkZone'
import { useAuth } from '@praxis/component-auth'
import { ProtectedElement } from '@praxis/component-auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { QueryDefaults } from 'trueye-component-library'
import './index.css'
import _ from 'lodash'
import useMyTVS, { CAMERA_TREE, FAVORITES_MODE, MONITOR_MODE } from './useMyTvs'
import { CameraMap } from './pages/WorkZone/hooks/useWorkzone'
import { getADgroupsAppList, hasAccessToRBAC } from './services/adGroupList'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: QueryDefaults.STALE_TIME,
      cacheTime: QueryDefaults.CACHE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
})

const Main = () => {
  const [cameraDrawerOpen, setCameraDrawerOpen] = useState<boolean>(false)
  const auth = useAuth()
  const [adGroups, setAdGroups] = useState<any[]>()
  auth.session?.validate()
  // eslint-disable-next-line
  const [hideAccess, setHideAccess] = useState<boolean>(
    !hasAccessToRBAC(auth.session?.userInfo?.memberOf ?? []),
  )
  const lanId = auth.session?.userInfo?.lanId ?? '' // TODO reconsider how we init this, and it's corresponding typing
  const authToken = auth.session?.userInfo?.accessToken ?? ''
  const userLegalName = auth.session?.userInfo?.fullName ?? ''
  const sessionId = _.uniqueId()
  const {
    createFavoriteOpen,
    closeCreateFavorite,
    currentMode,
    openCreateFavorite,
    setCurrentMode,
    setVideoIsPlaying,
    videoIsPlaying,
  } = useMyTVS()

  useEffect(() => {
    getADgroupsAppList(10).then((response: any) => {
      setAdGroups(response.data.adgroups)
    })
  }, [])

  // useEffect(() => {
  //   if (
  //     auth.session?.userInfo?.memberOf.includes('app-oauth2-mytvs-nonapuser')
  //   ) {
  //     setHideAccess(true)
  //   } else {
  //     setHideAccess(false)
  //   }
  // }, [auth])

  return (
    <QueryClientProvider client={queryClient}>
      <ProtectedElement allowed={adGroups}>
        <Helmet defaultTitle="MyTVS 3.0" titleTemplate="%s - MyTVS 3.0" />
        <Header
          hasFeature={
            currentMode === MONITOR_MODE || currentMode === FAVORITES_MODE
          }
          headerIcon={cameraDrawerOpen ? ChevronLeftIcon : MenuIcon}
          featureOpen={cameraDrawerOpen}
          headerText={cameraDrawerOpen ? CAMERA_TREE : currentMode}
          onIconClick={() => setCameraDrawerOpen((val) => !val)}
          onCreateFavorite={openCreateFavorite}
          showCreateFavorite={!createFavoriteOpen && videoIsPlaying}
        />
        <WorkZone
          cameraDrawerOpen={cameraDrawerOpen}
          currentMode={currentMode}
          lanId={lanId}
          onLogout={auth.logout}
          setCameraDrawerOpen={setCameraDrawerOpen}
          setCurrentMode={setCurrentMode}
          sessionId={sessionId}
          authToken={authToken}
          userLegalName={userLegalName}
          createFavoriteOpen={createFavoriteOpen}
          onCloseCreateFavorite={closeCreateFavorite}
          setVideoIsPlaying={(cameras: CameraMap) =>
            setVideoIsPlaying(!!cameras.size)
          }
          hideAccess={hideAccess}
        />
      </ProtectedElement>
    </QueryClientProvider>
  )
}

export default Main
