import { useEffect, useState } from 'react'
import { Layout } from 'trueye-component-library'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'

export type CameraID = string
export type CameraMap = Map<CameraID, CameraTreeItem>

export default function useWorkZone(onCamerasChanged: Function) {
  const [selectedCameras, setSelectedCameras] = useState<CameraMap>(new Map())
  const [currentPlayingLayout, setCurrentPlayingLayout] =
    useState<Layout | null>(null)

  useEffect(() => {
    onCamerasChanged(selectedCameras)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCameras])

  function addCameras(cameras: CameraMap) {
    const newSelectedCameras = new Map(selectedCameras)
    for (let camera of cameras.values()) {
      newSelectedCameras.set(camera.id, camera)
    }
    setSelectedCameras(newSelectedCameras)
  }

  function removeCamera(cameraId: string) {
    const newSelectedCameras = new Map(selectedCameras)
    newSelectedCameras.delete(cameraId)
    setSelectedCameras(newSelectedCameras)
  }

  function playFavoriteOnGrid(layout: Layout | null) {
    setCurrentPlayingLayout(layout)
    const newCameraMap = new Map()
    layout?.layout.forEach((camera) => {
      newCameraMap.set(camera.id, camera)
    })
    setSelectedCameras(newCameraMap)
  }

  return {
    addCameras,
    playFavoriteOnGrid,
    removeCamera,
    selectedCameras,
    setCurrentPlayingLayout,
    currentPlayingLayout,
  }
}
