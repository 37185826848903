import '@enterprise-ui/canvas-ui-css'
import {
  AddLayoutButton,
  CameraTreeButton,
  ModeName,
  StyledHeader,
} from './styles'
import { HeaderItems } from './styles'
import EnterpriseIcon, { CancelIcon, PlusIcon } from '@enterprise-ui/icons'
import { FunctionComponent } from 'react'
import './Header.css'
import CameraDomeIcon from '../CameraDrawer/icons/cameraDomeIcon.svg'
import { CAMERA_TREE, MONITOR_MODE } from '../../useMyTvs'

interface HeaderProps {
  headerText: string
  headerIcon: FunctionComponent<any>
  onIconClick: Function
  hasFeature: boolean
  featureOpen: boolean
  onCreateFavorite: Function
  showCreateFavorite: boolean
}

const Header = ({
  hasFeature,
  headerText,
  headerIcon,
  onIconClick,
  featureOpen,
  onCreateFavorite,
  showCreateFavorite,
}: HeaderProps) => {
  return (
    <StyledHeader>
      <HeaderItems>
        {hasFeature && !featureOpen && (
          <CameraTreeButton onClick={onIconClick} type="ghost">
            <EnterpriseIcon size={'md'} icon={headerIcon} />
          </CameraTreeButton>
        )}
        {headerText === CAMERA_TREE && <CameraDomeIcon />}
        <ModeName>{headerText}</ModeName>
        {hasFeature && featureOpen && (
          <CameraTreeButton
            onClick={onIconClick}
            type="ghost"
            className="close-button"
          >
            <EnterpriseIcon size={'md'} icon={CancelIcon} />
          </CameraTreeButton>
        )}
        {headerText === MONITOR_MODE && showCreateFavorite && (
          <AddLayoutButton onClick={onCreateFavorite} type="ghost">
            <EnterpriseIcon icon={PlusIcon} />
            Favorites
          </AddLayoutButton>
        )}
      </HeaderItems>
    </StyledHeader>
  )
}

export default Header
