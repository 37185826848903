import '@enterprise-ui/canvas-ui-css'
import { StoreSelectContainer, CameraTreeOverlay } from './styles'
import { Layout, StoreSearch } from 'trueye-component-library'
import apiConfig from '../../apiConfig'
import './CameraDrawer.css'

import { CameraTree } from '../CameraTree'
import useDrawer, { CAMERA_TREE_MODE, FAVORITES_MODE } from './hooks/useDrawer'
import { CameraMap } from '../../pages/WorkZone/hooks/useWorkzone'
import Location from 'trueye-component-library/dist/models/location/Location'
import { Button, ButtonGroup } from '@enterprise-ui/canvas-ui-react'
import { CameraTabs } from '../CameraTree/styles'
import Favorites from '../../pages/Favorites'

interface CameraDrawerProps {
  drawerIsOpen?: boolean
  addCameras: Function
  removeCamera: Function
  selectedCameras: CameraMap
  lanId: string
  onLayoutClear: Function
  onShowLayout: (layout: Layout | null) => void
  currentPlayingLayout: Layout | null
}

export const CameraDrawer = ({
  drawerIsOpen,
  addCameras,
  selectedCameras,
  lanId,
  onLayoutClear,
  onShowLayout,
  currentPlayingLayout,
}: CameraDrawerProps) => {
  const {
    drawerMode,
    openLocationData,
    setDrawerMode,
    setOpenLocationData,
    closeLocation,
  } = useDrawer()

  return (
    <CameraTreeOverlay className={drawerIsOpen !== false ? '--is-open' : ''}>
      <CameraTabs>
        <ButtonGroup>
          <Button
            className={drawerMode === CAMERA_TREE_MODE ? 'selected-tab' : ''}
            onClick={() => setDrawerMode(CAMERA_TREE_MODE)}
          >
            {CAMERA_TREE_MODE}
          </Button>
          <Button
            className={drawerMode === FAVORITES_MODE ? 'selected-tab' : ''}
            onClick={() => setDrawerMode(FAVORITES_MODE)}
          >
            {FAVORITES_MODE}
          </Button>
        </ButtonGroup>
      </CameraTabs>
      {drawerMode === CAMERA_TREE_MODE && (
        <>
          {!openLocationData && (
            <StoreSelectContainer>
              <StoreSearch
                apiURL={apiConfig.camera?.location as string} // todo, remove this hack and ensure this is strongly typed
                onSelectedLocation={(locationData: Location) => {
                  setOpenLocationData(locationData)
                }}
              />
            </StoreSelectContainer>
          )}
          {openLocationData && (
            <CameraTree
              addCameras={addCameras}
              onCloseCameraTree={closeLocation}
              openLocationData={openLocationData}
              selectedCameras={selectedCameras}
            />
          )}
        </>
      )}
      {drawerMode === FAVORITES_MODE && (
        <Favorites
          lanId={lanId}
          onShowLayoutClick={onShowLayout}
          onClearClick={() => onLayoutClear()}
          playingLayout={currentPlayingLayout}
        />
      )}
    </CameraTreeOverlay>
  )
}

export default CameraDrawer
