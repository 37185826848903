import ModeSelectBar from '../../components/ModeSelectBar'
import { FeatureSpace } from './styles'
import CameraDrawer from '../../components/CameraDrawer'
import Monitor from '../../pages/Monitor'
import RBAC from '../../pages/RBAC'
import Profile from '../../pages/Profile'
import useWorkZone, { CameraMap } from './hooks/useWorkzone'
import { AppMode, MONITOR_MODE, PROFILE_MODE, RBAC_MODE } from '../../useMyTvs'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

interface WorkZoneProps {
  cameraDrawerOpen: boolean
  currentMode: AppMode
  lanId: string
  onLogout: Function
  setCameraDrawerOpen: Function
  setCurrentMode: Function
  sessionId: string
  authToken: string
  userLegalName: string
  createFavoriteOpen: boolean
  onCloseCreateFavorite: Function
  setVideoIsPlaying: Function
  hideAccess: boolean
}

const WorkZone = ({
  cameraDrawerOpen,
  currentMode,
  lanId,
  onLogout,
  setCameraDrawerOpen,
  setCurrentMode,
  sessionId,
  authToken,
  userLegalName,
  createFavoriteOpen,
  onCloseCreateFavorite,
  setVideoIsPlaying,
  hideAccess,
}: WorkZoneProps) => {
  const {
    addCameras,
    playFavoriteOnGrid,
    removeCamera,
    selectedCameras,
    setCurrentPlayingLayout,
    currentPlayingLayout,
  } = useWorkZone(setVideoIsPlaying)

  return (
    <section>
      <CameraDrawer
        drawerIsOpen={cameraDrawerOpen}
        addCameras={(cameraMap: CameraMap) => {
          addCameras(cameraMap)
          setCameraDrawerOpen(false)
        }}
        removeCamera={removeCamera}
        selectedCameras={selectedCameras}
        // Camera Drawer Now needs the props
        lanId={lanId}
        onLayoutClear={() => setCurrentPlayingLayout(null)}
        onShowLayout={(favorite) => {
          playFavoriteOnGrid(favorite)
          setCameraDrawerOpen(false)
        }}
        currentPlayingLayout={currentPlayingLayout}
      />
      <ToastProvider location="bottom">
        <FeatureSpace>
          {currentMode === MONITOR_MODE && (
            <Monitor
              lanId={lanId}
              authToken={authToken}
              removeCamera={removeCamera}
              selectedCameras={selectedCameras}
              sessionId={sessionId}
              onLogoClick={() => setCameraDrawerOpen(true)}
              createFavoriteOpen={createFavoriteOpen} // TODO make this dynamic
              onCloseCreateFavorite={onCloseCreateFavorite}
            />
          )}
          {currentMode === RBAC_MODE && <RBAC lanId={lanId} />}
          {currentMode === PROFILE_MODE && (
            <Profile userLegalName={userLegalName} onLogoutClick={onLogout} />
          )}
        </FeatureSpace>
      </ToastProvider>
      <ModeSelectBar
        currentMode={currentMode}
        setcurrentMode={setCurrentMode}
        hideAccess={hideAccess}
      />
    </section>
  )
}

export default WorkZone
