import styled from 'styled-components'
import { Button, ButtonGroup, Input } from '@enterprise-ui/canvas-ui-react'

export const PopoverContainer = styled.div`
  position: relative; /* The parent div is the reference point */
  min-width: 100px;
`

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: 5px; /* Align to the top */
  right: 0; /* Align to the right */
  background-color: #333333;
  padding: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* Set a fixed width to give it a card-like size */
  z-index: 100; /* Ensure it's above other elements */

  h1 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
  }

  .C-Input {
    background: transparent;
  }

  .confirm-button {
    color: pink;
  }

  @media (max-width: 768px) {
    width: 100vw; /* Full screen width on mobile */
    left: 0; /* Ensures it's aligned to the left on mobile */
    right: 0; /* Ensures full width */
    border-radius: 0; /* Remove rounded corners for mobile */
  }
`

export const FavoriteFieldLabel = styled(Input.Label)`
  color: #ffffff;
  font-size: 14px;
`

export const FavoriteFieldText = styled.p`
  color: #9e9e9e;
`

export const CreateFavoriteText = styled(Input.Text)`
  width: 80%;
  color: #ffffff;
`

export const LayoutToggleGroup = styled(ButtonGroup)`
  // justify-content: center;
  width: 100%;
  /* Remove rounded corners for all buttons */
  border-radius: 0;

  /* Apply rounded corners to the first button */
  &:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  /* Apply rounded corners to the last button */
  &:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  /* Optional: Adjust button border when next to another button */
  &:not(:last-child) {
    border-right: none; /* To avoid double borders */
  }
`

export const LayoutToggleButton = styled(Button)`
  border: none;

  &.\--type-primary {
    background: #90caf9 !important;
    color: #1e1e1e;
  }

  &.\--type-secondary {
    background: #252525 !important;
    color: #9e9e9e;
  }
`

export const SaveLayoutGroup = styled.div`
  margin-left: auto;
`

export const CancelButton = styled(Button)`
  &.\--type-ghost {
    color: #90caf9;
  }
`

export const ConfirmButton = styled(Button)`
  &.\--type-primary {
    background: #90caf9 !important;
    color: #121212;
  }
  &:disabled {
    opacity: 0.5;
  }
`
