import styled from 'styled-components'
import { Button, Input } from '@enterprise-ui/canvas-ui-react'
import { HEADER_HEIGHT } from '../Header/styles'
import { LOCATION_BANNER_HEIGHT } from '../LocationHeader/styles'

export const CAMERA_ACTION_BUTTON_HEIGHT = 60
export const CAMERA_TREE_TAB_HEIGHT = 60

export const CameraSelectContainer = styled.div`
  height: calc(
    100vh - ${HEADER_HEIGHT}px - ${CAMERA_ACTION_BUTTON_HEIGHT}px -
      ${CAMERA_TREE_TAB_HEIGHT}px
  );
`
export const CameraTreeContainer = styled.div`
  background: #333333;
  height: calc(100vh - 116px - ${LOCATION_BANNER_HEIGHT}px);
  overflow: auto;
`

export const CameraTabs = styled.header`
  display: flex;
  height: ${CAMERA_TREE_TAB_HEIGHT}px;

  .C-ButtonGroup {
    flex-grow: 1;
  }

  .C-ButtonGroup__ListItem {
    flex-basis: 0;
    flex-grow: 1;
  }

  .C-Button {
    background: #2e2e2e;
    border: none;
    border-radius: 0px !important;
    color: #bdbdbd;
    padding: 0;
    width: 100%;

    &.selected-tab {
      border-bottom: 2px solid #42a5f5;
      color: white;
    }
  }
`

export const CameraSearchContainer = styled.div`
  padding: 9px 8px;
  position: relative;
`

export const CameraSeachBar = styled(Input.Text)`
  background: transparent;
  border: 1px solid #979797;
  border-radius: 0;
  color: #ffffff;
  height: 42px;
`

export const CameraSearchIcon = styled.span`
  color: #979797;
  position: absolute;
  right: 20px;
  top: 20px;
`

export const CameraActionContainer = styled.footer`
  display: flex;
  height: ${CAMERA_ACTION_BUTTON_HEIGHT}px;

  .C-ButtonGroup {
    flex-grow: 1;
  }

  .C-ButtonGroup__ListItem {
    flex-basis: 0;
    flex-grow: 1;
  }

  .C-Button {
    border-radius: 0px !important;
    padding: 0;
  }
`

export const CameraActionButton = styled(Button)`
  border: none;
  color: #c4c4c4;
  font-size: 12px;
  flex-direction: column;
  gap: 2px;
  flex-basis: 0;

  &.\--type-primary {
    svg {
      color: #76b7f7;
    }
    background: #90caf9 !important;
    color: #121212;
  }

  &.\--type-secondary {
    svg {
      color: #76b7f7;
    }
    color: #90caf9;
    background: #222222 !important;
  }

  &:disabled {
    opacity: 0.5;
  }
`
