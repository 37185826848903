import RBACCard from '../../components/RBACCard'
import { CardContainer } from '../../components/CardGrid/styles'
import useAccess from './hooks/useAccess'
import ActionModal from '../../components/ActionModal'
import AccessRequestData from '../../models/AccessRequestData'
import './RBAC.css'
import { StreamingSpinner } from 'trueye-component-library'
import NothingToShowMessage from '../../components/NothingToShowMessage'

interface RbacProps {
  lanId: string
}

const RBAC = ({ lanId }: RbacProps) => {
  const {
    closeModal,
    isError,
    isFetching,
    isSuccess,
    locationAccessRequests,
    locationAccessData,
    modalIsRejectMode,
    modalOpen,
    onApprove,
    onReject,
    submitApproval,
    submitRejection,
  } = useAccess(lanId)

  if (isFetching) {
    return <StreamingSpinner message="Loading requests..." />
  }

  if (isSuccess) {
    if (locationAccessRequests?.length <= 0) {
      return (
        <NothingToShowMessage text="You have no pending approval requests at the moment" />
      )
    }
    return (
      <div>
        {modalOpen && (
          <ActionModal
            actionButtonText={modalIsRejectMode ? 'Reject' : 'Approve'}
            isNegativeAction={modalIsRejectMode}
            isVisible={modalOpen}
            onCancel={closeModal}
            onPrimaryAction={
              modalIsRejectMode
                ? (rejectReason: string) =>
                    submitRejection(
                      rejectReason,
                      locationAccessData as AccessRequestData,
                    )
                : () => {
                    submitApproval(locationAccessData as AccessRequestData)
                  }
            }
            prompt={
              modalIsRejectMode
                ? 'Reason for Decline'
                : 'Are you sure you want to approve this request?'
            }
            textBox={modalIsRejectMode}
            textBoxCharLimit={255}
          />
        )}
        <CardContainer>
          {locationAccessRequests.map((accessRequest: AccessRequestData) => (
            <RBACCard
              onApprove={() => onApprove(accessRequest)}
              onReject={() => onReject(accessRequest)}
              key={accessRequest.la_id}
              requestData={accessRequest}
              lanId={lanId}
            />
          ))}
        </CardContainer>
      </div>
    )
  }

  if (isError) {
    return <p>Something went wrong. Please try again</p>
  }
}

export default RBAC
