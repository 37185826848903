import '@enterprise-ui/canvas-ui-css'
import {
  CustomModal,
  Heading,
  Box,
  Buttons,
  Cancel,
  ActionButton,
  TextBox,
  CharacterCounter,
} from './styles'
import { ChangeEvent, useState } from 'react'
import EnterpriseIcon, { CancelIcon, CheckIcon } from '@enterprise-ui/icons'

interface ActionModalProps {
  isNegativeAction: boolean
  isVisible: boolean
  onCancel: Function
  onPrimaryAction: Function
  prompt: string
  textBox: boolean
  textBoxCharLimit: number
  actionButtonText: string
}

const ActionModal = ({
  isNegativeAction,
  isVisible,
  onCancel,
  onPrimaryAction,
  prompt,
  textBox,
  textBoxCharLimit,
  actionButtonText,
}: ActionModalProps) => {
  const [text, setText] = useState<string>('')

  return (
    <CustomModal isVisible={isVisible}>
      <Box>
        <Heading>{prompt}</Heading>
        {textBox && (
          <>
            <TextBox
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                if (event?.target?.value.length <= textBoxCharLimit) {
                  setText(event?.target?.value)
                }
              }}
              value={text}
            />
            {textBoxCharLimit && (
              <CharacterCounter>{`${text.length}/${textBoxCharLimit}`}</CharacterCounter>
            )}
          </>
        )}

        <Buttons>
          <Cancel onClick={() => onCancel()}>Cancel</Cancel>
          <ActionButton
            className={`${isNegativeAction ? '--negative-action' : ''}`}
            onClick={() =>
              textBox ? onPrimaryAction(text) : onPrimaryAction()
            }
          >
            <EnterpriseIcon icon={isNegativeAction ? CancelIcon : CheckIcon} />{' '}
            {actionButtonText}
          </ActionButton>
        </Buttons>
      </Box>
    </CustomModal>
  )
}

export default ActionModal
